import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { Link } from '../components/Link'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="ミスプラチナの価格について"
        description="ミスプラチナは工場直販のため原価に近い低価格でご利用頂けます。"
        page="price"
    />
)

const PricePage: React.FC = () => {
    const muiTheme = useTheme()
    return (
        <Layout breadcrumbs={[{ name: '低価格の理由' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <section>
                        <H1>
                            <StaticImage
                                src="../images/price/price-0.jpg"
                                alt="ミスプラチナの価格について"
                            />
                        </H1>
                        <TxtWrapper>
                            <Txt size="s">
                                純度と強度を両立する高級メタル、PT950ハードプラチナ。{' '}
                            </Txt>
                            <Txt size="s">
                                センターのダイヤは大手鑑定機関を通った確かなグレード。
                            </Txt>
                            <Txt size="s">
                                熟練の技術で仕上げるこだわりの品質。
                            </Txt>
                            <Txt size="s">
                                ではなぜMissPlatinumは比較的低価格なのか。
                            </Txt>
                        </TxtWrapper>
                        <StaticImage src="../images/price/price-1.jpg" alt="" />
                        <TxtWrapper>
                            <Txt size="s">
                                コストのかかる店舗展開をしない代わりに試着用リングをお客様のご自宅へ全国無料発送。
                            </Txt>
                            <Txt size="s">
                                メーカー直販で余計なマージンがなく広告費より品質にリソースを集中したジュエリー工場直販のため、原価に近い価格になっています。
                            </Txt>
                        </TxtWrapper>
                        <StaticImage src="../images/price/price-2.jpg" alt="" />
                        <TxtWrapper>
                            <Txt size="s">
                                同じ品質であれば1gあたりの価格、1ctあたりの価格はどのメーカーもほぼ同一。
                            </Txt>
                        </TxtWrapper>
                        <StaticImage src="../images/price/price-3.jpg" alt="" />
                        <TxtWrapper>
                            <Txt size="s">
                                販売価格が各メーカー毎に異なるのは、店舗コストやブランディングコストがそれぞれ異なるからです。
                            </Txt>
                        </TxtWrapper>
                        <StaticImage src="../images/price/price-4.jpg" alt="" />
                        <TxtWrapper>
                            <Txt size="s">
                                品質に集中し、品質に特化し、品質を極めることで、原価に近い販売価格も実現されていく。
                            </Txt>
                            <Txt size="s">
                                それが工場「ワークショップ」に直接オーダーするブライダルジュエリーの特徴となっています。
                            </Txt>
                        </TxtWrapper>
                        <StaticImage src="../images/price/price-5.jpg" alt="" />
                        <StaticImage src="../images/price/price-6.jpg" alt="" />
                        <StaticImage src="../images/price/price-7.jpg" alt="" />
                    </section>
                    <section>
                        <Wrapper theme={muiTheme}>
                            <H2 theme={muiTheme}>
                                <Txt size="s">
                                    東京渋谷ワークショップで試着する
                                </Txt>
                            </H2>
                            <Body theme={muiTheme}>
                                <ImageWrapper>
                                    <StaticImage
                                        src="../images/about/salon.jpg"
                                        alt=""
                                        width={190}
                                        imgStyle={{ width: '190px' }}
                                        objectFit="contain"
                                    />
                                </ImageWrapper>
                                <Description>
                                    <Txt size="s">
                                        MissPlatinumは工場直販の通販を中心にご利用頂いておりますが、事前にご試着をご希望のお客様は是非、渋谷のワークショップショールームへお越しくださいませ。
                                        製作現場を見ながら、ショースペースではゆっくりとすべての製品を自由にご試着頂けます。
                                    </Txt>
                                    <Link to="/salon/" margin="8px 0">
                                        MissPlatinumワークショップショールームの詳細はこちら
                                    </Link>
                                </Description>
                            </Body>
                        </Wrapper>
                        <Wrapper theme={muiTheme}>
                            <H2 theme={muiTheme}>
                                <Txt size="s">
                                    自宅でラクラク♪ 無料サンプルで試着する
                                </Txt>
                            </H2>
                            <Body theme={muiTheme}>
                                <ImageWrapper>
                                    <StaticImage
                                        src="../images/about/rental.jpg"
                                        alt=""
                                        width={190}
                                        imgStyle={{ width: '190px' }}
                                        objectFit="contain"
                                    />
                                </ImageWrapper>
                                <Description>
                                    <Txt size="s">
                                        遠方のお客様、忙しく見学が難しいお客様はご自宅に試着用のサンプルリングを無料でお届け致します。
                                        おふたりでゆっくりとお試し頂ければ、通販でも安心。只今日本全国、絶賛発送中です。
                                    </Txt>
                                    <Link to="/rental/" margin="8px 0">
                                        試着用サンプルの詳細はこちら
                                    </Link>
                                </Description>
                            </Body>
                        </Wrapper>
                    </section>
                </Grid>
            </Grid>
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
})
const H2 = styled('h2')(({ theme }) => ({
    lineHeight: 1,
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
}))
const TxtWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 8px',
})
const Wrapper = styled('div')(({ theme }) => ({
    margin: '40px 8px 0',
    border: `1px solid ${theme.palette.secondary.main}`,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row-reverse',
    },
}))
const ImageWrapper = styled('div')({
    minWidth: '190px',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px',
})
const Description = styled('p')({
    display: 'flex',
    flexDirection: 'column',
    margin: '12px',
})

export default PricePage
